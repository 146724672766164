const isCompensationValid = (compLimit, totalCompensation) => {
  return compLimit > 0 && totalCompensation >= compLimit;
};

const countryArtCompensationCheck = (
  articleCompLimit,
  articleTotalCompensation
) => {
  return isCompensationValid(articleCompLimit, articleTotalCompensation);
};

const countryArtNonCompensationCheck = (
  nonArticleCompLimit,
  nonArticleTotalCompensation
) => {
  return isCompensationValid(nonArticleCompLimit, nonArticleTotalCompensation);
};

export { countryArtCompensationCheck, countryArtNonCompensationCheck };
