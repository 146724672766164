import React from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Select, Option } from "@ingka/select";
import "./languageSelector.css";

import { fetchAppDataAsync } from "../Calculator/calculatorSlice";
import { getLastSelectedCountry } from '../CountrySettings/countrySettingsSlice';
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";

let enabledCountries = JSON.parse(process.env.REACT_APP_ENABLED_COUNTRIES)
  .sort((country1, country2) => (country1.name > country2.name) ? 1 : -1);

const gaEventTracker = sendAnalyticsEvent('Page_Visit');

const LanguageSelector = () => {
  // Hooks
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const preselectedLanguage = getLastSelectedCountry("en-US");

  const changeLanguage = (event) => {
    localStorage.setItem('selected-language', event.target.value);

    i18n.changeLanguage(event.target.value);

    gaEventTracker('Language_selected')
    // Fetch the data corresponding to the newly selected country/language frm the api
    dispatch(fetchAppDataAsync(event.target.value));
  }

  return (
    <div onChange={changeLanguage} className={"selector"}>
      {/* 
      The vales set in the inputs ex. es-ES must match the name of the json files
       located in public/assets/i18n/translations 
       */}
      <Select label="Select a language" value={preselectedLanguage}>
        {enabledCountries.map((country) => (
          <Option key={country.code} value={country.code} name={country.name}>
            {country.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default LanguageSelector