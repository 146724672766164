import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Button from "@ingka/button";
import "@ingka/button/dist/style.css";

import {setToastMessage} from "../../features/Toast/toastSlice";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const RedirectSignInButton = () => {
  
  const { instance } = useMsal();
  const { t } = useTranslation();
  const signIn = t("signIn");
  const errMessage = t("errorFetching");
  const dispatch = useDispatch();

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
    dispatch(
      setToastMessage({
        toastRequired: true,
        toastMessage: errMessage,
      })
    );
  });
}


  return (
    <Button
      aria-disabled="false"
      type="emphasised"
      className="btn btn--emphasised"
      onClick={() => handleLogin(instance)}
      small={true}
    >
      <div style={{ color: "white" }}>{signIn}</div>
    </Button>
  );
};
