import {  createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  issueTypeSelected: null,
  periodPurchasedSelected: null,
  status: 'idle',
};

export const formModuleThreeSlice = createSlice({
  name: 'calc',
  initialState,
  reducers: {
    setIssueType: (state, action) => {
      state.issueTypeSelected = action.payload.issueType;
    },
    setPeriodPurchased: (state, action) => {
      state.periodPurchasedSelected = action.payload.periodPurchased;
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    
  },
});

export const { 
  setIssueType,
  setPeriodPurchased,
  resetState,
 } = formModuleThreeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIssueType = (state) => state.formModuleThree.issueTypeSelected;
export const selectPeriodPurchased = (state) => state.formModuleThree.periodPurchasedSelected;
