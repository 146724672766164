const SSOAuth = process.env.REACT_APP_SSO_AUTHORITY
const SSOClientId = process.env.REACT_APP_SSO_CLIENT_ID
const callbackUrl = process.env.REACT_APP_SSO_CALLBACK_URI

export const msalConfig = {
    auth: {
        clientId: SSOClientId,
        authority: SSOAuth,
        redirectUri: callbackUrl,
        knownAuthorities: [SSOAuth]
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};