import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  failedServiceTotal: 0,
  timeSeverityTotal: 0,
  mileageTotal: 0,
  additional: 0,
  assemblyCompTotal: 0,
  cannotCookResult: 0,
  faultyProductResult: 0,
  totalCompensation: 0,
  status: "idle",
};

export const calculatorResultsSlice = createSlice({
  name: "compTotal",
  initialState,
  reducers: {
    setFailedServiceTotal: (state, action) => {
      state.failedServiceTotal = action.payload.failedServiceTotal;
    },
    setTimeSeverityTotal: (state, action) => {
      state.timeSeverityTotal = action.payload.timeSeverityTotal;
    },
    setMileageTotal: (state, action) => {
      state.mileageTotal = action.payload.mileageTotal;
    },
    setAdditional: (state, action) => {
      state.additional = action.payload.additional;
    },
    setAssemblyCompTotal: (state, action) => {
      state.assemblyCompTotal = action.payload.assemblyCompTotal;
    },
    setCannotCookResult: (state, action) => {
      state.cannotCookResult = action.payload.cannotCookResult;
    },
    setFaultyProductResult: (state, action) => {
      state.faultyProductResult = action.payload.faultyProductResult;
    },
    setTotalCompensation: (state, action) => {
      state.totalCompensation = action.payload.totalCompensation;
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    },
  },

  extraReducers: (builder) => { },
});

export const {
  setFailedServiceTotal,
  setTimeSeverityTotal,
  setMileageTotal,
  setAdditional,
  setAssemblyCompTotal,
  setCannotCookResult,
  setFaultyProductResult,
  setTotalCompensation,
  resetState,
} = calculatorResultsSlice.actions;

export const selectFailedServiceTotal = (state) =>
  state.compTotal.failedServiceTotal;
export const selectTimeSeverityTotal = (state) =>
  state.compTotal.timeSeverityTotal;
export const selectMileageTotal = (state) => state.compTotal.mileageTotal;
export const selectAdditional = (state) => state.compTotal.additional;
export const selectAssemblyCompTotal = (state) => state.compTotal.assemblyCompTotal;
export const selectCannotCookResult = (state) => state.compTotal.cannotCookResult;
export const selectFaultyProductResult = (state) => state.compTotal.faultyProductResult;
export const selectTotalCompensation = (state) => state.compTotal.totalCompensation;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

//export const selectServiceFailed = (state) => state.calculator.failedServiceOptionSelected;
//export const selectUnableProductDays = (state) => state.calculator.unableToUseOptionSelected;
//export const selectProductType = (state) => state.calculator.productTypeOptionSelected;
//export const selectAdditions = (state) => state.calculator.additionsSelected;
//export const selectIssueType = (state) => state.calculator.issueTypeSelected;
// export const selectPeriodPurchased = (state) => state.calculator.periodPurchasedSelected;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectServiceFailed(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default calculatorSlice.reducer;
 */
