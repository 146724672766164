import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
//Ingka styling
import "@ingka/button/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import '@ingka/loading/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/text/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import "@ingka/typography/dist/style.css";
import Choice, { ChoiceItem } from "@ingka/choice";
import InputField from "@ingka/input-field";
import Loading, { LoadingBall } from "@ingka/loading";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";

import {
  selectDeliveryPrice,
  setServiceFailedSelect,
  setDeliveryPrice,
} from '../FormModuleOne/formModuleOneSlice';

import {
  setUnableProductDays,
  selectProductPrice,
  setProductType,
  setProductPrice,
  setAdditions,
  setTravelDistance,
  setServicePrice,
  setNumberOfPersons,
  setDaysNoCook,
  selectServicePrice,
  selectNumberOfPersons,
  selectTravelDistance,
  selectDaysNoCook,
} from "../FormModuleTwo/formModuleTwoSlice";

import {
  selectIsEditing,
  selectCountrySettingsStatus,
} from "../CountrySettings/countrySettingsSlice";
import styles from "./categoryModuleOne.module.css";
import { CountrySettings } from "../CountrySettings/CountrySettings";
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";

const CategoryModuleOneComp = () => {
  const [deliveryPriceInputIsVisible, setDeliveryPriceInputIsVisible] = useState(false);
  const isEditing = useSelector(selectIsEditing);
  const status = useSelector(selectCountrySettingsStatus);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [selectedUnableProductDays, setSelectedUnableProductDays] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const productPriceValue = useSelector(selectProductPrice);
  const servicePriceValue = useSelector(selectServicePrice);
  const numberOfPersonsValue = useSelector(selectNumberOfPersons);
  const travelDistanceValue = useSelector(selectTravelDistance);
  const daysNoCookValue = useSelector(selectDaysNoCook);

  // select Addition
  const [additionSelected, setAdditionSelected] = useState("");
  /*
  full validation is in backend.
  this use effect just checks if the user has an admin group
  in order to display the country settings blue button toggle
  */

  // Selectors
  const deliveryPriceValue = useSelector(selectDeliveryPrice);

  // Dispatch
  const dispatch = useDispatch();

  // Translations
  const { t } = useTranslation();

  // Handlers
  const serviceFailedSelectHandler = (evt) => {
    dispatch(setServiceFailedSelect({ serviceFailed: evt }));
    // Check if the clicked item is the currently selected one
    if (evt === selectedChoice) {
      // Reset the input field value when the same item is clicked again
      dispatch(setDeliveryPrice({ deliveryPrice: "" }));
      setSelectedChoice(null);
    } else {
      // Set the selectedChoice state to the clicked item
      setSelectedChoice(evt);
    }
    // to enable/disable deliveryPrice input
    if (evt === "mod1Title.Option3") {
      setDeliveryPriceInputIsVisible(false);
    } else {
      setDeliveryPriceInputIsVisible(true);
    }
  };

  // Handlers for product Days
  const unableProductDaysHandler = (evt) => {
    if (evt === selectedUnableProductDays) {
      // Reset the choice when the same item is clicked again
      dispatch(setUnableProductDays({ unableToUseProductDays: "" }));
      setSelectedUnableProductDays(null);
    } else {
      // Set the selectedUnableProductDays state to the clicked item
      dispatch(setUnableProductDays({ unableToUseProductDays: evt }));
      setSelectedUnableProductDays(evt);
    }
  };

  const deliveryPriceHandler = (evt) => {
    dispatch(setDeliveryPrice({ deliveryPrice: evt.target.value }));
  };

  const productTypeHandler = (evt) => {
    if (evt === selectedProductType) {
      // Reset the choice when the same item is clicked again
      dispatch(setProductType({ productType: "" }));
      setSelectedProductType(null);
    } else {
      // Set the selectedProductType state to the clicked item
      dispatch(setProductType({ productType: evt }));
      setSelectedProductType(evt);
    }
  };

  const productPriceHandler = (evt) => {
    dispatch(setProductPrice({ productPrice: evt.target.value }));
  };

  const additionsHandler = (evt) => {
    if (evt === additionSelected) {
      // Reset the choice when the same item is clicked again
      dispatch(setAdditions({ addition: "" }));
      setAdditionSelected("");
    } else {
      // Set the additionSelected state to the clicked item
      dispatch(setAdditions({ addition: evt }));
      setAdditionSelected(evt);
      if (additionSelected === "mod2Title3.Option2") {
        dispatch(setTravelDistance({ travelDistance: "" }));
      } else if (additionSelected === "mod2Title3.Option3") {
        dispatch(setServicePrice({ servicePrice: "" }));
      } else if (additionSelected === "mod2Title3.Option4") {
        dispatch(setNumberOfPersons({ numberOfPersons: "" }));
        dispatch(setDaysNoCook({ daysNoCook: "" }));
      }
    }
  };
  const travelDistanceHandler = (evt) => {
    dispatch(setTravelDistance({ travelDistance: evt.target.value }));
  };
  const servicePriceHandler = (evt) => {
    dispatch(setServicePrice({ servicePrice: evt.target.value }));
  };
  const personsInHouseholdHandler = (evt) => {
    dispatch(setNumberOfPersons({ numberOfPersons: evt.target.value }));
  };
  const daysNoCookHandler = (evt) => {
    dispatch(setDaysNoCook({ daysNoCook: evt.target.value }));
  };

  const gaEventTracker = sendAnalyticsEvent('delivery_information');

  return (
    < >
      <div>
        <div
          style={{ display: isEditing ? "block" : "none" }}
          className="lang-edit-wrapper"
        >
          {status === 'loading' && <div className={styles.spinner2}>
            <Loading text={t("loading")} labelTransitions>
              <LoadingBall />
            </Loading>
          </div>
          }{status === 'idle' &&
            <CountrySettings />}
        </div>
      </div>
      <div className={styles.moduleContainer}>

        <div className={styles.sectionContainer}>

          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("category1.Header")}
              </Text>
            </div>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod1Title.Text")}
              </Text>
              <Tooltip tooltipText={t("mod1Title.Tooltip")} />
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod1Title.Option1")}
                    id="mod1Title.Option1"
                    onClick={(evt) => { gaEventTracker('YesWithNotice'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod1Title.Option2")}
                    id="mod1Title.Option2"
                    onClick={(evt) => { gaEventTracker('YesWithNoNotice'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod1Title.Option3")}
                    id="mod1Title.Option3"
                    onClick={(evt) => { gaEventTracker('No'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.userContainer}>
          <div className={styles.userEntryContainer}>
            {deliveryPriceInputIsVisible && (
              <div className={styles.hideShow}>
                <label
                  htmlFor="mod1_deliveryPrice"
                  className={styles.userLabel}
                >
                  {t("mod1Input.Label")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  value={deliveryPriceValue || ""}
                  min="0"
                  onChange={(evt) => { gaEventTracker('DeliveryPriceInput'); return deliveryPriceHandler(evt, dispatch) }} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.sectionContainer}>
          {/** Title */}
          <div className={styles.module}>
            {/** <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("mod2Header.Text")}
              </Text>
            </div> */}

            {/*** 1st title *****   How long will the customer be unable to use the product/s   ****/}
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title1.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title1.Option1")}
                    id="mod2Title1.Option1"
                    onClick={(evt) => { gaEventTracker('LessThanWeek'); return unableProductDaysHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option2")}
                    id="mod2Title1.Option2"
                    onClick={(evt) => { gaEventTracker('TwoWeeks'); return unableProductDaysHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option3")}
                    id="mod2Title1.Option3"
                    onClick={(evt) => { gaEventTracker('FourWeeks'); return unableProductDaysHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option4")}
                    id="mod2Title1.Option4"
                    onClick={(evt) => { gaEventTracker('MoreThan7Weeks'); return unableProductDaysHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>
          <div className={styles.module}>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title2.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title2.Option1")}
                    id="mod2Title2.Option1"
                    onClick={(evt) => { gaEventTracker('CriticalSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title2.Option2")}
                    id="mod2Title2.Option2"
                    onClick={(evt) => { gaEventTracker('AnnoyingSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title2.Option3")}
                    id="mod2Title2.Option3"
                    onClick={(evt) => { gaEventTracker('MinimalSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
            <div className={styles.userEntryContainer}>
              <label htmlFor="mod2_productPrice" className={styles.userLabel}>
                {t("mod2Input.Label1")}
              </label>
              <InputField
                type="number"
                step="any"
                name="mod2_productPrice"
                value={productPriceValue || 0}
                min="0"
                onChange={(evt) => { gaEventTracker('ProductPriceInput'); return productPriceHandler(evt, dispatch) }}
              />
            </div>
          </div>
          <div className={styles.module}>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title3.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title3.Option2")}
                    id="mod2Title3.Option2"
                    onClick={(evt) => { gaEventTracker('Drive'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <div className={styles.userEntryContainer}>
                    <label
                      htmlFor="mod2_travel_distance"
                      className={styles.userLabel}
                    >
                      {t("mod2Input.Label4")}
                    </label>
                    <InputField
                      type="number"
                      step="any"
                      name="mod2_travel_distance"
                      value={travelDistanceValue || 0}
                      min="0"
                      onChange={(evt) => { gaEventTracker('DriveDistanceTravelledInput'); return travelDistanceHandler(evt, dispatch) }}
                    />
                  </div>
                  <ChoiceItem
                    title={t("mod2Title3.Option3")}
                    id="mod2Title3.Option3"
                    onClick={(evt) => { gaEventTracker('AssembleDismantle'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <div className={styles.userEntryContainer}>
                    <label
                      htmlFor="mod2_servicePrice"
                      className={styles.userLabel}
                    >
                      {t("mod2Input.Label2")}
                    </label>
                    <InputField
                      type="number"
                      step="any"
                      name="mod2_servicePrice"
                      value={servicePriceValue || 0}
                      min="0"
                      onChange={(evt) => { gaEventTracker('AssembleDismantleServiceInput'); return servicePriceHandler(evt, dispatch) }}
                    />
                  </div>
                  <ChoiceItem
                    title={t("mod2Title3.Option4")}
                    id="mod2Title3.Option4"
                    onClick={(evt) => { gaEventTracker('NoCookingEatOut'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
            <div className={styles.userContainer}>
              <div className={styles.userEntryContainer}>
                <label
                  htmlFor="mod2_personsHousehold"
                  className={styles.userLabel}
                >
                  {t("mod2Input.Label3")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  name="mod2_personsHousehold"
                  value={numberOfPersonsValue || 0}
                  min="0"
                  onChange={(evt) => { gaEventTracker('PeopleInHouseholdInput'); return personsInHouseholdHandler(evt, dispatch) }}
                />
              </div>
              <div className={styles.userEntryContainer}>
                <label htmlFor="mod2_daysNoCook" className={styles.userLabel}>
                  {t("mod2Input.Label5")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  name="mod2_daysNoCook"
                  value={daysNoCookValue || 0}
                  min="0"
                  onChange={(evt) => { gaEventTracker('UnableToCookDaysInput'); return daysNoCookHandler(evt, dispatch) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CategoryModuleOne = withTranslation()(CategoryModuleOneComp);
export { CategoryModuleOne };
