import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calculator } from "../Calculator/Calculator";
import { CalculatorResults } from "../CalculatorResults/CalculatorResults";
import { useTranslation, withTranslation } from "react-i18next";
import "./MainCalculator.css";
// ingka styles
import '@ingka/loading/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import "@ingka/focus/dist/style.css";
import '@ingka/loading/dist/style.css';
import '@ingka/toast/dist/style.css';
import Loading, { LoadingBall } from "@ingka/loading";
import InlineMessage from "@ingka/inline-message";
import Toast from "@ingka/toast";
import Button from "@ingka/button";

import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { RedirectSignInButton } from "../RedirectSignInButton/RedirectSignInButton"
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";

import {
  selectToastRequired,
  selectToastMessage,
  setToastMessage,
  setToastRequired
} from "../../features/Toast/toastSlice";

import {
  selectLanguageCode,
  toggleIsEditing,
  selectCountrySettingsStatus,
  clearCountrySettingsError,
  selectCountrySettingsResponse,
} from "../CountrySettings/countrySettingsSlice";

import {
  selectSuperUser
} from "../FormModuleOne/formModuleOneSlice";

import { CategorySelector } from "../CategorySelector/CategorySelector";
import styles from "../FormModuleOne/formModuleOne.module.css";

const MainCalculatorComp = ({ statusValue }) => {
  // Translations
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toastRequired = useSelector(selectToastRequired);
  const toastMessage = useSelector(selectToastMessage);
  const lang = useSelector(selectLanguageCode);
  const countrySettingsStatus = useSelector(selectCountrySettingsStatus);
  const countrySettingsResponse = useSelector(selectCountrySettingsResponse);
  const [toastVisible, setToastVisible] = useState(false);
  const gaEventTracker = sendAnalyticsEvent('Page_Visit');

  const enabledCompensationCountries =
    process.env.REACT_APP_ENABLED_COMPENSATION_COUNTRIES?.split(",");

  const compensationSplitEnabled =
    enabledCompensationCountries.includes(lang);

  useEffect(() => {
    if (countrySettingsStatus === "error") {
      let errMess;
      if (countrySettingsResponse?.message === "user does not have permission to edit this market") {
        errMess = t("countrySettingsPermissionError");
      } else {
        errMess = t("errorFetching");
      }
      dispatch(setToastMessage({ toastMessage: errMess }));
      dispatch(toggleIsEditing());
      dispatch(clearCountrySettingsError());
      setToastVisible(true);
      return
    }
    else if (toastRequired) {

      let successMessage = t("settingsUpdated");
      dispatch(setToastMessage({ toastMessage: successMessage }));
      setToastVisible(true);
      return

    }
  }, [countrySettingsStatus, countrySettingsResponse?.message, t, dispatch, lang, toastRequired])

  // Toggle edit country settings form/box
  const toggleContrySettingsEditorHandler = () => {
    dispatch(toggleIsEditing());
  };

  const isAdmin = useSelector(selectSuperUser);

  useEffect(() => {
    if (statusValue === 'rejected') {
      gaEventTracker('not_logged_in')
    } else {
      gaEventTracker('logged_in')
    }
  }, [gaEventTracker, statusValue])

  return (
    <>
      <AuthenticatedTemplate>
        {statusValue === "loading" && (
          <div className="spinner">
            <Loading text={t("loading")} labelTransitions>
              <LoadingBall />
            </Loading>
          </div>
        )}
        {statusValue === "idle" && (
          <div className="main-wrapper">
            {isAdmin &&
              <div className={styles.btnEditCountrySettingsWrapper}>
                <div className={styles.btnEditCountrySettings}>
                  <Button
                    type="emphasised"
                    small={true}
                    onClick={() => toggleContrySettingsEditorHandler()}
                    text={t("countrySettings")} />
                </div>
              </div>
            }
            {compensationSplitEnabled === true ? <CategorySelector /> : <Calculator />}
            {/* <Calculator /> */}
            <CalculatorResults />
            <Toast
              text={toastMessage}
              isOpen={toastVisible}
              onCloseRequest={() => {
                setToastVisible(false);

                dispatch(setToastRequired({ toastRequired: false }));
              }}
              ariaLabelCloseBtn="Dismiss notification" />
          </div>
        )}

        {statusValue === "rejected" && (
          <InlineMessage
            title={t("error")}
            body={t("errorFetching")}
            variant="negative"
            className="errorWrap"
          />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="not-logged-in-warning-wrapper">
          <RedirectSignInButton />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};
const MainCalculator = withTranslation()(MainCalculatorComp);

export default MainCalculator;
