import React from "react";
import ReactLogo from "../../ikea-logo.svg";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

import styles from "./header.module.css";

const Header = () => {
  return (
    <header className={styles.moduleContainer}>
      <img src={ReactLogo} alt="ikea" />
      <h1 className="title"> iCare </h1>
      <LanguageSelector />
    </header>
  );
};

export default Header;
