const cannotCook = (numberOfPeople, price, days) => {
  let intPeople = parseInt(numberOfPeople);
  let floatPrice = parseFloat(price);
  // currently working on one meal a day, but this may change.
  let floatDays = parseFloat(days);

  let initialFigure = intPeople * floatPrice * floatDays;
  let result = Math.round(initialFigure * 1e2) / 1e2;
  return result;
};

export default cannotCook;

