const askedToAssembleDisassembleCalculator = (servicePrice) => {
  let numericPrice = parseFloat(servicePrice);
  if (isNaN(numericPrice)) {
    return 0;
  }
  let initialFigure = numericPrice / 2;
  let result = Math.round(initialFigure * 1e2) / 1e2;
  return result;
};

export default askedToAssembleDisassembleCalculator;
