const timeSeverityCalculator = (time, productType, productPriceValue) => {


  let floatProductPrice = parseFloat(productPriceValue);  // should no longer be required as set in dispatch
  let severity;
  if (productType === "mod2Title2.Option1") {
    severity = "critical";
  } else if (productType === "mod2Title2.Option2") {
    severity = "sensitive";
  } else if (productType === "mod2Title2.Option3") {
    severity = "regular";
  }
  let comp;
  let compFormat;
  switch (time) {
    case "mod2Title1.Option1":  //Less than a week"
      if (severity === "critical") {
        // voucher
        comp = "voucher";
        compFormat = "voucher";
      } else if (severity === "sensitive") {
        // nada
        comp = null;
        compFormat = null;
        return 0;
      } else if (severity === "regular") {
        // nada
        comp = null;
        compFormat = null;
        return 0;
      } else {
        return 0;
      }
      break;

    case "mod2Title1.Option2": //"1-2 weeks"
      if (severity === "critical") {
        comp = 0.1;
        compFormat = "percentage"; // deliveryPricePercentage ???
      } else if (severity === "sensitive") {
        comp = 0.05;
        compFormat = "percentage";
      } else if (severity === "regular") {
        // nada
        comp = null;
        compFormat = null;
        return 0;
      } else {
        return 0;
      }
      break;

    case "mod2Title1.Option3":  //"3-6 weeks":
      if (severity === "critical") {
        // 20%
        comp = 0.2;
        compFormat = "percentage";
      } else if (severity === "sensitive") {
        // 10%
        comp = 0.1;
        compFormat = "percentage";
      } else if (severity === "regular") {
        // voucher
        comp = "voucher";
        compFormat = "voucher";
      } else {
        return 0;
      }
      break;

    case "mod2Title1.Option4": // "7 or more weeks":
      if (severity === "critical") {
        // 30%
        comp = 0.3;
        compFormat = "percentage";
      } else if (severity === "sensitive") {
        // 20%
        comp = 0.2;
        compFormat = "percentage";
      } else if (severity === "regular") {
        // 5%
        comp = 0.05;
        compFormat = "percentage";
      } else {
        return 0;
      }
      break;
    default: return 0;
  }

  // voucher, null, %
  if (compFormat === "voucher") {
    return "voucher";
  // } else if (compFormat === null) {
  // //   return 0;
  } else if (compFormat === "percentage") {
    let calculatedComp =  parseFloat(floatProductPrice) * comp;
    return calculatedComp;
  }
};

export default timeSeverityCalculator;
