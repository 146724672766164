import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
// import { InfoBubble } from '../tooltip/Tooltip';

import '@ingka/typography/dist/style.css';
import '@ingka/text/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";
import Text from "@ingka/text";
import Choice, { ChoiceItem } from "@ingka/choice";


import { setIssueType, setPeriodPurchased } from "./formModuleThreeSlice";
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";

import styles from "./formModuleThree.module.css";

const FormModuleThreeComp = () => {

  const [selectedIssueType, setSelectedIssueType] = useState(null);
  const [selectedPeriodPurchased, setSelectedPeriodPurchased] = useState(null);

  // Dispatch
  const dispatch = useDispatch();

  // Translations
  const { t } = useTranslation();

  // Handlers
  const issueTypeHandler = (evt) => {
    if (evt === selectedIssueType) {
      // Reset the choice and input field value when the same item is clicked again
      dispatch(setIssueType({ issueType: "" }));
      setSelectedIssueType(null);
    } else {
      // Set the selectedIssueType state to the clicked item
      dispatch(setIssueType({ issueType: evt }));
      setSelectedIssueType(evt);
    }
  };
  const periodPurchasedHandler = (evt) => {
    if (evt === selectedPeriodPurchased) {
      // Reset the choice and input field value when the same item is clicked again
      dispatch(setPeriodPurchased({ periodPurchased: "" }));
      setSelectedPeriodPurchased(null);
    } else {
      // Set the selectedPeriodPurchased state to the clicked item
      dispatch(setPeriodPurchased({ periodPurchased: evt }));
      setSelectedPeriodPurchased(evt);
    }
  };

  const gaEventTracker = sendAnalyticsEvent('keep_faulty_product_compensation');

  return (
    <div>
      <div className={styles.moduleContainer}>
        {/* Start options section*/}
        <div className={styles.sectionContainer}>
          {/* 1st title*/}
          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("mod3Header.Text")}
              </Text>
            </div>
            <div className={styles.subtitle1}>
              <Text tagName="h4" headingSize="s">
                {t("mod3Title1.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              {/*1st option*/}
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod3Title1.Option1")}
                    id="mod3Title1.Option1"
                    isSelected={selectedIssueType === "mod3Title1.Option1"}
                    onClick={(evt) => { gaEventTracker('NonFunctional'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 1st option*/}
                  {/*2nd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title1.Option2")}
                    id="mod3Title1.Option2"
                    isSelected={selectedIssueType === "mod3Title1.Option2"}
                    onClick={(evt) => { gaEventTracker('AestheticHeavy'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 2nd option*/}
                  {/*3rd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title1.Option3")}
                    id="mod3Title1.Option3"
                    isSelected={selectedIssueType === "mod3Title1.Option3"}
                    onClick={(evt) => { gaEventTracker('AestheticLight'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* End 3rd option*/}
                </Choice>
              </div>
            </div>
          </div>
          {/* End 1st title*/}
          {/* 2nd title*/}
          <div className={styles.module}>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod3Title2.Text")}
              </Text>
              {/* <h5>NF | AH | AL</h5> */}
            </div>
            <div className={styles.inputsContainer}>
              {/*1st option*/}
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod3Title2.Option1")}
                    id="mod3Title2.Option1"
                    isSelected={selectedPeriodPurchased === "mod3Title2.Option1"}
                    onClick={(evt) => { gaEventTracker('OneMonth'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 1st option*/}
                  {/*2nd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option2")}
                    id="mod3Title2.Option2"
                    isSelected={selectedPeriodPurchased === "mod3Title2.Option2"}
                    onClick={(evt) => { gaEventTracker('OneYear'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 2nd option*/}
                  {/*3rd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option3")}
                    id="mod3Title2.Option3"
                    isSelected={selectedPeriodPurchased === "mod3Title2.Option3"}
                    onClick={(evt) => { gaEventTracker('LessThan2Years'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 3rd option*/}
                  {/*4th option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option4")}
                    id="mod3Title2.Option4"
                    isSelected={selectedPeriodPurchased === "mod3Title2.Option4"}
                    onClick={(evt) => { gaEventTracker('MoreThan2Years'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* End 4th option*/}
                </Choice>
              </div>
            </div>
            {/* End 2nd title*/}
          </div>
        </div>
        {/* End options section*/}
      </div>
    </div>
  );
};

const FormModuleThree = withTranslation()(FormModuleThreeComp);
export { FormModuleThree };
