import { createSlice } from "@reduxjs/toolkit";
// import { fetchCount } from './counterAPI';

const initialState = {
  /* options */
  unableToUseOptionSelected: null,
  productTypeOptionSelected: null,
  additionsSelected: null,
  /* user inputs */
  productPriceValue: 0,
  servicePriceValue: 0,
  numberOfPersonsInHousehold: 0,
  travelDistanceValue: 0,
  daysNoCookValue: 0,
  explanation: "",
  status: "idle",
};

export const formModuleTwoSlice = createSlice({
  name: "calc",
  initialState,
  reducers: {
    /* options reducers */
    setUnableProductDays: (state, action) => {
      state.unableToUseOptionSelected = action.payload.unableToUseProductDays;
    },
    setProductType: (state, action) => {
      state.productTypeOptionSelected = action.payload.productType;
    },
    setAdditions: (state, action) => {
      state.additionsSelected = action.payload.addition;
    },
    /* user input reducers */

    setProductPrice: (state, action) => {
      let productPrice = action.payload.productPrice;
      if (productPrice.trim() === '') {
        state.productPriceValue = 0; // or null
      }
      else if (!isNaN(productPrice)) {
        const noOfDots = productPrice.split("").filter(char => char === ".").length
        if (noOfDots < 2) {
          if (!productPrice.endsWith(".")) {
            productPrice = parseFloat(productPrice);
          }
          state.productPriceValue = productPrice;
        }
      }
    },

    setServicePrice: (state, action) => {
      let servicePrice = action.payload.servicePrice;
      if (servicePrice.trim() === '') {
        state.servicePriceValue = 0; // or null
      }
      else if (!isNaN(servicePrice)) {
        const noOfDots = servicePrice.split("").filter(char => char === ".").length
        if (noOfDots < 2) {
          if (!servicePrice.endsWith(".")) {
            servicePrice = parseFloat(servicePrice);
          }
          state.servicePriceValue = servicePrice;
        }
      }
    },
    setNumberOfPersons: (state, action) => {
      if (action.payload.numberOfPersons.length === 0) action.payload.numberOfPersons = 0;
      state.numberOfPersonsInHousehold = parseInt(action.payload.numberOfPersons);
    },
    // setPricePerMeal: (state, action) => {
    //   state.pricePerMealValue = action.payload.pricePerMeal;
    // },

    setTravelDistance: (state, action) => {
      let travelDistance = action.payload.travelDistance;
      if (travelDistance.trim() === '') {
        state.travelDistanceValue = 0; // or null
      }
      else if (!isNaN(travelDistance)) {
        const noOfDots = travelDistance.split("").filter(char => char === ".").length
        if (noOfDots < 2) {
          if (!travelDistance.endsWith(".")) {
            travelDistance = parseFloat(travelDistance);
          }
          state.travelDistanceValue = travelDistance;
        }
      }
    },

    setDaysNoCook: (state, action) => {
      if (action.payload.daysNoCook.length === 0) action.payload.daysNoCook = 0;
      state.daysNoCookValue = parseFloat(action.payload.daysNoCook);
    },
    setExplanation: (state, action) => {
      state.explanation = action.payload.explanation;
    },

    resetState: (state) => {
      Object.assign(state, initialState)
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => { },
});

export const {
  setUnableProductDays,
  setProductType,
  setAdditions,
  setProductPrice,
  setServicePrice,
  setNumberOfPersons,
  // setPricePerMeal,
  setTravelDistance,
  setDaysNoCook,
  setExplanation,
  resetState,
} = formModuleTwoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// options
export const selectUnableProductDays = (state) =>
  state.formModuleTwo.unableToUseOptionSelected;
export const selectProductType = (state) =>
  state.formModuleTwo.productTypeOptionSelected;
export const selectAdditionType = (state) => state.formModuleTwo.additionsSelected;
// user inputs
export const selectProductPrice = (state) => state.formModuleTwo.productPriceValue;
export const selectServicePrice = (state) => state.formModuleTwo.servicePriceValue;
export const selectNumberOfPersons = (state) =>
  state.formModuleTwo.numberOfPersonsInHousehold;
// export const selectPricePerMeal = (state) => state.formModuleTwo.pricePerMeal;
export const selectDaysNoCook = (state) => state.formModuleTwo.daysNoCookValue;
export const selectExplanation = (state) => state.formModuleTwo.explanation;
// export const selectTravelDistance = (state) => state.formModuleTwo.travelDistance;
export const selectTravelDistance = (state) => state.formModuleTwo.travelDistanceValue;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectServiceFailed(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default calculatorSlice.reducer;
 */
