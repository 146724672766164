import ReactGA4 from 'react-ga4';

const sendAnalyticsEvent = (categoryName) => {
    const existingLanguage = localStorage.getItem('selected-language');
    const eventTracker = (label) => {
        try {
            ReactGA4.event({ category: categoryName, action: existingLanguage, label});
        } catch (e) {
            console.error('error in sending GA event', e)
        }
    }
    return eventTracker;
}

export default sendAnalyticsEvent;