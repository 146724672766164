import { configureStore } from '@reduxjs/toolkit';
import { calculatorSlice } from '../components/Calculator/calculatorSlice';
import { formModuleOneSlice } from '../components/FormModuleOne/formModuleOneSlice';
import { formModuleTwoSlice } from '../components/FormModuleTwo/formModuleTwoSlice';
import { formModuleThreeSlice } from '../components/FormModuleThree/formModuleThreeSlice';
import { calculatorResultsSlice } from '../components/CalculatorResults/calculatorResultsSlice';
import { countrySettingsSlice } from '../components/CountrySettings/countrySettingsSlice'
import { toastSlice } from '../features/Toast/toastSlice';


export const store = configureStore({
  reducer: {
    formModuleOne: formModuleOneSlice.reducer,
    formModuleTwo: formModuleTwoSlice.reducer,
    formModuleThree: formModuleThreeSlice.reducer,
    appData: calculatorSlice.reducer,
    compTotal: calculatorResultsSlice.reducer,
    countrySettings: countrySettingsSlice.reducer,
    toast: toastSlice.reducer
  },
});
