const failedServiceCalculator = (deliveryPrice, notice, splitFlag) => {
  // early return for useless cases and or bad data:
  if (
    notice === null ||
    notice === false ||
    notice === "mod1Title.Option3" ||
    deliveryPrice === 0 ||
    deliveryPrice === isNaN
  ) {
    return 0;
  }

  if(splitFlag === true){
    return { deliveryPrice, notice };
  }else{
    if (notice === "mod1Title.Option1") {
      // ("Yes, with a notice");
      return deliveryPrice;
    } else if (notice === "mod1Title.Option2") {
      // ("Yes, with NO notice");
      let noNoticeAddon = deliveryPrice / 2;
      let noNoticeTotal = deliveryPrice + noNoticeAddon;
      return noNoticeTotal;
    }
  }
  
};

export default failedServiceCalculator;
