const mileageCalculator = (pricePerKm, distance) => {
  let numericPrice = parseFloat(pricePerKm);
  let numericDistance = parseFloat(distance);
  if (isNaN(numericPrice)) {
    return 0;
  }
  let provisionalResult = numericDistance * numericPrice;

  // 2 decimal places only please
  let result = Math.round(provisionalResult * 1e2) / 1e2;

  return result;
};

export default mileageCalculator;
