import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import { getLastSelectedCountry } from "./components/CountrySettings/countrySettingsSlice";

const langToUse = getLastSelectedCountry('en-US')
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    useSuspense : true ,
    lng: langToUse,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: langToUse,
    debug: process.env.NODE_ENV === 'production' ? false: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
  })

export default i18n