// import React, { useEffect } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import currency from "currency.js";
// import { useTranslation, withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

// calculation utils
import failedServiceCalculator from "../../utils/failedServiceCalculator";
import timeSeverityCalculator from "../../utils/timeSeverityCalculator";
import mileageCalculator from "../../utils/mileageCalculator";
import askedToAssembleDisassembleCalculator from "../../utils/askedToAssembleDisassembleCalculator";
import cannotCook from "../../utils/cannotCook";
import keepFaultyProduct from "../../utils/keepFaultyProduct";
import {
  countryArtCompensationCheck,
  countryArtNonCompensationCheck,
} from "../../utils/countryCompensationCheck";

// Ingka modules
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import Button from "@ingka/button";

import styles from "./CalculatorResults.module.css";

// Selectors

import {
  selectServiceFailed,
  selectDeliveryPrice,
} from "../FormModuleOne/formModuleOneSlice";

import {
  selectUnableProductDays,
  selectProductType,
  selectExplanation,
  selectTravelDistance,
  selectServicePrice,
  selectNumberOfPersons,
  selectDaysNoCook,
  selectProductPrice,
} from "../FormModuleTwo/formModuleTwoSlice";

import {
  selectIssueType,
  selectPeriodPurchased,
} from "../FormModuleThree/formModuleThreeSlice";

import {
  selectPricePerKm,
  selectCurrencySign,
  selectPricePerMeal,
  selectArtCompensationLimit,
  selectNonArtCompensationLimit,
} from "../Calculator/calculatorSlice";

import {
  setFailedServiceTotal,
  selectFailedServiceTotal,
  setTimeSeverityTotal,
  selectTimeSeverityTotal,
  setMileageTotal,
  selectMileageTotal,
  setAssemblyCompTotal,
  selectAssemblyCompTotal,
  setCannotCookResult,
  selectCannotCookResult,
  setFaultyProductResult,
  selectFaultyProductResult,
} from "./calculatorResultsSlice";

import { selectLanguageCode } from "../CountrySettings/countrySettingsSlice";

export const CalculatorResults = () => {
  // Translations
  const { t } = useTranslation();
  // Hooks
  const dispatch = useDispatch();

  const failedService = useSelector(selectServiceFailed);
  const deliveryPrice = useSelector(selectDeliveryPrice);
  const unableToUseProductDays = useSelector(selectUnableProductDays);
  const productType = useSelector(selectProductType);
  const issueType = useSelector(selectIssueType);
  const periodPurchased = useSelector(selectPeriodPurchased);
  const explanationValue = useSelector(selectExplanation);
  const kmPrice = useSelector(selectPricePerKm);
  const distanceTravelled = useSelector(selectTravelDistance);
  const currencySign = useSelector(selectCurrencySign);
  const servicePriceValue = useSelector(selectServicePrice);
  const numberOfPersonsValue = useSelector(selectNumberOfPersons);
  const pricePerMealValue = useSelector(selectPricePerMeal);
  const artCompensationLimit = useSelector(selectArtCompensationLimit);
  const nonArtCompensationLimit = useSelector(selectNonArtCompensationLimit);
  const daysNoCookValue = useSelector(selectDaysNoCook);
  const productPriceValue = useSelector(selectProductPrice);
  const languageCodeValue = useSelector(selectLanguageCode);

  // Totals
  const failedServiceTotalValue = useSelector(selectFailedServiceTotal);
  const timeSeverityTotalValue = useSelector(selectTimeSeverityTotal);
  const mileageTotalValue = useSelector(selectMileageTotal);
  const assemblyTotalValue = useSelector(selectAssemblyCompTotal);
  const cannotCookTotalValue = useSelector(selectCannotCookResult);
  const faultyProdTotalValue = useSelector(selectFaultyProductResult);
  // titles
  const title1 = t("calcResult.Title1");
  const title2 = t("calcResult.Title2");
  const title3 = t("calcResult.Title3");
  const title4 = t("calcResult.Title4");
  const title6 = t("calcResult.Title6");
  const title7 = t("calcResult.Title7");
  const title8 = t("calcResult.Title8");
  const finalResult = t("calcResult.Result");
  const finalResultArticleComp = t("calcResult.ResultArticleComp");
  const finalResultNonArticleComp = t("calcResult.ResultNonArticleComp");
  const compensationLimitExceeded = t(
    "calcResult.countryCompensationLimitExceeded"
  );

  const enabledCompensationCountries =
    process.env.REACT_APP_ENABLED_COMPENSATION_COUNTRIES?.split(",");

  const compensationSplitEnabled =
    enabledCompensationCountries.includes(languageCodeValue);

  // UTIL FUNCTIONS

  let failedServiceTotal = failedServiceCalculator(
    deliveryPrice,
    failedService,
    compensationSplitEnabled
  );
  dispatch(
    setFailedServiceTotal({
      failedServiceTotal: compensationSplitEnabled
        ? failedServiceTotal["deliveryPrice"]
        : failedServiceTotal,
    })
  );

  let timeSeverityTotal = timeSeverityCalculator(
    unableToUseProductDays,
    productType,
    productPriceValue
  );
  // let timeSeverityTotal = 0;
  dispatch(setTimeSeverityTotal({ timeSeverityTotal: timeSeverityTotal }));

  let mileageTotal = mileageCalculator(kmPrice, distanceTravelled);
  dispatch(setMileageTotal({ mileageTotal: mileageTotal }));

  // askedToAssembleDisassemble returns number
  let assemblyCompTotal =
    askedToAssembleDisassembleCalculator(servicePriceValue);

  // let assemblyCompTotal = 0;
  dispatch(setAssemblyCompTotal({ assemblyCompTotal: assemblyCompTotal }));

  // cannotCook
  // args numberOfPeople, price, days
  // returns number
  let cannotCookResult = cannotCook(
    numberOfPersonsValue,
    pricePerMealValue,
    daysNoCookValue
  );
  dispatch(setCannotCookResult({ cannotCookResult: cannotCookResult }));

  // keepfaulty
  // faultType, timePeriod, productPrice
  // returns number

  let faultyProductResult = keepFaultyProduct(
    issueType,
    periodPurchased,
    productPriceValue
  );
  dispatch(
    setFaultyProductResult({ faultyProductResult: faultyProductResult })
  );

  let totalCompensation;
  let articleTotalCompensation;
  let nonArticleTotalCompensation;
  let voucherLabel = "";

  const severityMap = {
    "mod2Title2.Option1": "critical",
    "mod2Title2.Option2": "sensitive",
    "mod2Title2.Option3": "regular",
  };

  const severity = severityMap[productType];

  //check if any of the input values have value more then 0
  const hasPositiveValue = [
    failedServiceTotalValue,
    cannotCookTotalValue,
    timeSeverityTotalValue,
    mileageTotalValue,
    assemblyTotalValue,
    faultyProdTotalValue,
  ].some((value) => value > 0);

  if (timeSeverityTotal === "voucher" && hasPositiveValue) {
    voucherLabel = "";
    articleTotalCompensation = faultyProdTotalValue;
    nonArticleTotalCompensation =
      cannotCookTotalValue + mileageTotalValue + assemblyTotalValue;
    totalCompensation =
      failedServiceTotalValue +
      cannotCookTotalValue +
      mileageTotalValue +
      assemblyTotalValue +
      faultyProdTotalValue;

    //tranform voucher to value for certain scenarious
    if (productType === "mod2Title2.Option1" && severity === "critical") {
      const voucherAmount = 0.05 * productPriceValue;
      totalCompensation += voucherAmount;
      articleTotalCompensation += voucherAmount;
    } else if (productType === "mod2Title2.Option3" && severity === "regular") {
      const voucherAmount = 0.025 * productPriceValue;
      totalCompensation += voucherAmount;
      articleTotalCompensation += voucherAmount;
    }
  } else if (timeSeverityTotal === "voucher" && !hasPositiveValue) {
    voucherLabel = "voucher";
  } else {
    voucherLabel = "";

    if (compensationSplitEnabled) {
      articleTotalCompensation = faultyProdTotalValue;

      nonArticleTotalCompensation =
        cannotCookTotalValue +
        timeSeverityTotalValue +
        assemblyTotalValue +
        mileageTotalValue;
    } else {
      totalCompensation =
        failedServiceTotalValue +
        cannotCookTotalValue +
        timeSeverityTotalValue +
        mileageTotalValue +
        assemblyTotalValue +
        faultyProdTotalValue;
    }
  }

  //if title1 is selected then adds failed service total to only non article comp as working earlier
  if (failedServiceTotal["notice"] === "mod1Title.Option1") {
    nonArticleTotalCompensation += failedServiceTotalValue;
  } else if (failedServiceTotal["notice"] === "mod1Title.Option2") {
    //if title2 is selected then adds filed service total to article comp and half of it to non article comp
    //this change is based on CASE-3041
    nonArticleTotalCompensation += failedServiceTotalValue / 2;
    articleTotalCompensation += failedServiceTotalValue;
  }

  const currencyFormatOptions = {
    "€": { symbol: "€", separator: ".", decimal: "." },
    NOK: { symbol: "NOK ", separator: ".", decimal: "." },
    CHF: { symbol: "CHF ", separator: ".", decimal: "." },
    "£": { symbol: "£ ", separator: ".", decimal: "." },
    SEK: { symbol: "SEK ", separator: ".", decimal: "." },
    $: { symbol: "$ ", separator: ".", decimal: "." },
    zł: { symbol: "zł ", separator: ",", decimal: "." },
    "₹": { symbol: "₹ ", separator: ",", decimal: "." },
    HUF: { symbol: "HUF ", separator: ",", decimal: "." },
    CZK: { symbol: "CZK ", separator: ",", decimal: "." },
    "¥": { symbol: "¥ ", separator: ",", decimal: "." },
  };

  const compensationCheckArt = countryArtCompensationCheck(
    artCompensationLimit,
    articleTotalCompensation
  );
  const compensationCheckNonArt = countryArtNonCompensationCheck(
    nonArtCompensationLimit,
    nonArticleTotalCompensation
  );

  if (totalCompensation) {
    const formatOptions = currencyFormatOptions[currencySign] || {};
    totalCompensation = currency(totalCompensation, formatOptions).format();
  }
  if (articleTotalCompensation) {
    const formatOptions = currencyFormatOptions[currencySign] || {};
    articleTotalCompensation = currency(
      articleTotalCompensation,
      formatOptions
    ).format();
  }
  if (nonArticleTotalCompensation) {
    const formatOptions = currencyFormatOptions[currencySign] || {};
    nonArticleTotalCompensation = currency(
      nonArticleTotalCompensation,
      formatOptions
    ).format();
  }

  let summaryText = "";
  if (compensationSplitEnabled) {
    summaryText =
      `${title1}  ${t(failedService)}\n` +
      `${title2}  ${currencySign} ${deliveryPrice}\n` +
      `${title3}  ${t(unableToUseProductDays)}\n` +
      `${title4}  ${t(productType)}\n` +
      `${title6}  ${t(issueType)}\n` +
      `${title7}  ${t(periodPurchased)}\n` +
      `${title8}  ${explanationValue}\n` +
      `${finalResultArticleComp} ${articleTotalCompensation} ${voucherLabel}\n` +
      `${finalResultNonArticleComp} ${nonArticleTotalCompensation} ${voucherLabel}\n` +
      `${finalResult} ${totalCompensation} ${voucherLabel}\n`;
  } else {
    summaryText =
      `${title1}  ${t(failedService)}\n` +
      `${title2}  ${currencySign} ${deliveryPrice}\n` +
      `${title3}  ${t(unableToUseProductDays)}\n` +
      `${title4}  ${t(productType)}\n` +
      `${title6}  ${t(issueType)}\n` +
      `${title7}  ${t(periodPurchased)}\n` +
      `${title8}  ${explanationValue}\n` +
      `${finalResult} ${currencySign} ${totalCompensation} ${voucherLabel}\n`;
  }

  return (
    <div>
      <div className={styles.resultsContainer}>
        <div className={styles.results}>
          <div className={styles.resultsTitle}>
            {/* TODO: test not harcoded */}
            <h1> {t("calcResult.Header")}</h1>
          </div>
          <div className={styles.formResults}>
            {/* Start main container*/}
            <div className={styles.resultsModule}>
              {/* Start summary container*/}
              <div className={styles.optionsSelected}>
                {title1} {` `} {t(failedService)}
                <br />
                <br />
                {title2} {currencySign} {deliveryPrice}
                <br />
                <br />
                {title3} {` `} {t(unableToUseProductDays)}
                <br />
                <br />
                {title4} {` `} {t(productType)}
                <br />
                <br />
                {title6} {` `} {t(issueType)}
                <br />
                <br />
                {title7} {` `} {t(periodPurchased)}
                <br />
                <br />
                {title8} {explanationValue}
                {/* End summary container*/}
              </div>

              <div className={styles.optionResult}>
                {compensationSplitEnabled ? (
                  <>
                    {compensationCheckArt ? (
                      <label htmlFor="articleCompensationTotal">
                        {finalResultArticleComp} {voucherLabel}
                        <span style={{ color: "red" }}>
                          {articleTotalCompensation}{" "}
                        </span>
                      </label>
                    ) : (
                      <label htmlFor="articleCompensationTotal">
                        {finalResultArticleComp} {voucherLabel}
                        {articleTotalCompensation}
                      </label>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {compensationCheckNonArt ? (
                      <label htmlFor="nonArticleCompensationTotal">
                        {finalResultNonArticleComp} {voucherLabel}
                        <span style={{ color: "red" }}>
                          {nonArticleTotalCompensation}
                        </span>
                      </label>
                    ) : (
                      <label htmlFor="nonArticleCompensationTotal">
                        {finalResultNonArticleComp} {voucherLabel}
                        {nonArticleTotalCompensation}
                      </label>
                    )}
                  </>
                ) : (
                  <label htmlFor="compensationTotal">
                    {finalResult} {voucherLabel}
                    {totalCompensation}
                  </label>
                )}
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                {(compensationCheckArt || compensationCheckNonArt) ? (
                  <span style={{ color: "red" }}>
                    {t(compensationLimitExceeded)}
                  </span>
                ) : null}
              </div>

              <div className={styles.separator}></div>

              <div className={styles.btnCopyWrapper}>
                <Button
                  type="emphasised"
                  onClick={() => {
                    navigator.clipboard.writeText(summaryText);
                  }}
                  text={t("buttonCopy")}
                />
              </div>
            </div>
            {/* End main container*/}
          </div>
        </div>
      </div>
    </div>
  );
};
