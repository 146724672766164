import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;


export function putCountrySettings({ lang, pricePerMeal, pricePerKm, accessToken, artCompensationPerLimit, nonArtCompensationPerLimit }) {
  const url = apiEndpoint + '/country/settings';
  return axios.put(url, { lang, pricePerMeal, pricePerKm, artCompensationPerLimit, nonArtCompensationPerLimit }, { headers: { Authorization : `Bearer ${accessToken}`
  }});
  }


