import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  failedServiceOptionSelected: null,
  deliveryPrice: 0,
  superUser: false,
  status: "idle",
};

export const formModuleOneSlice = createSlice({
  name: "calc",
  initialState,
  reducers: {
    setServiceFailedSelect: (state, action) => {
      state.failedServiceOptionSelected = action.payload.serviceFailed;
    },
    setDeliveryPrice: (state, action) => {
      let deliveryPrice = action.payload.deliveryPrice;
      if (deliveryPrice.trim() === '') {
        state.deliveryPrice = 0; // or null
      }
      else if (!isNaN(deliveryPrice)) {
        const noOfDots = deliveryPrice.split("").filter(char => char === ".").length
        if (noOfDots < 2) {
          if (!deliveryPrice.endsWith(".")) {
            deliveryPrice = parseFloat(deliveryPrice);
          }
          state.deliveryPrice = deliveryPrice;
        }
      }
    },

    setSuperUser: (state, action) => {
      state.superUser = action.payload.superUser;
    },

    resetState: (state) => {
      Object.assign(state, initialState)
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => { },
});

export const { setServiceFailedSelect, setDeliveryPrice, setSuperUser, resetState } =
  formModuleOneSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectServiceFailed = (state) =>
  state.formModuleOne.failedServiceOptionSelected;
export const selectDeliveryPrice = (state) => state.formModuleOne.deliveryPrice;
export const selectSuperUser = (state) => state.formModuleOne.superUser;
