const keepFaultyProduct = (faultType, timePeriod, productPrice) => {
  if (faultType === null || timePeriod === null) {
    return 0;
  }
  if (isNaN(productPrice)) {
    return 0;
  }

  let floatProductPrice = parseFloat(productPrice);
  let compensationPercentage;
  switch (timePeriod) {
    case "mod3Title2.Option1":  // Less than 1 month
      if (faultType === "mod3Title1.Option1") {
        // voucher
        compensationPercentage = 0.5;
      } else if (faultType === "mod3Title1.Option3") {
        // nada
        compensationPercentage = 0.2;
      } else if (faultType === "mod3Title1.Option2") {
        // nada
        compensationPercentage = 0.3;
      } else {
        return 0;
      }
      break;

    case "mod3Title2.Option2":  //"Less than 1 year":
      if (faultType === "mod3Title1.Option1") {
        // voucher
        compensationPercentage = 0.4;
      } else if (faultType === "mod3Title1.Option3") {
        // nada
        compensationPercentage = 0.1;
      } else if (faultType === "mod3Title1.Option2") {
        // nada
        compensationPercentage = 0.2;
      } else {
        return 0;
      }
      break;

    case "mod3Title2.Option3": //"Less than 2 years (within commercial warranty)",
      if (faultType === "mod3Title1.Option1") {
        // voucher
        compensationPercentage = 0.3;
      } else if (faultType === "mod3Title1.Option3") {
        // nada
        compensationPercentage = 0.05;
      } else if (faultType === "mod3Title1.Option2") {
        // nada
        compensationPercentage = 0.1;
      } else {
        return 0;
      }
      break;

    case "mod3Title2.Option4": //"More than 2 years (within commercial warranty)",
      if (faultType === "mod3Title1.Option1") {
        // voucher
        compensationPercentage = 0.2;
      } else if (faultType === "mod3Title1.Option3") {
        // nada
        return 0;
      } else if (faultType === "mod3Title1.Option2") {
        // nada
        compensationPercentage = 0.05;
      } else {
        return 0;
      }
      break;
    default: compensationPercentage = 0;
  }

  


  if (compensationPercentage === 0) {
    return floatProductPrice;
  }

  // let calculatedComp = (floatProductPrice += floatProductPrice * compensationPercentage);
  let calculatedComp = (floatProductPrice * compensationPercentage);

  return calculatedComp;
};

export default keepFaultyProduct;
