import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ReactGA from "react-ga";
import ReactGA4 from 'react-ga4';

import "./App.css";
import { fetchAppDataAsync, selectStatus } from "./components/Calculator/calculatorSlice";
import { selectLanguageCode } from "./components/CountrySettings/countrySettingsSlice";
import Header from "./components/Header/Header";
import MainCalculator from "./components/MainCalculator/MainCalculator";
import { getLastSelectedCountry } from "./components/CountrySettings/countrySettingsSlice";
import { useMsal } from "@azure/msal-react";

const TRACKING_ID = process.env.REACT_APP_GA_TAG_ID;
const App = () => {
  ReactGA4.initialize(TRACKING_ID);
  const dispatch = useDispatch();

  const statusValue = useSelector(selectStatus);
  const lang = useSelector(selectLanguageCode);

  const currentlang = getLastSelectedCountry(lang);

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(fetchAppDataAsync(currentlang));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const SuspenseFallbackHandler = () => <div> Fallback loading indicator</div>;

  return (
    <Suspense fallback={<SuspenseFallbackHandler />}>
      <Header />
      <Routes>
        <Route path="/authcallback" element={<MainCalculator statusValue={statusValue} />} />
        <Route path="/" element={<MainCalculator statusValue={statusValue} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
