import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { fetchAppDataAsync } from '../Calculator/calculatorSlice'
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import styles from "./countrySettings.module.css";



import { setToastMessage } from "../../features/Toast/toastSlice"


import {
  selectLanguageCode,
  selectPricePerMeal,
  selectPricePerKm,
  selectCurrencySign,
  putCountrySettingsAsync,
  selectArtCompensationLimit,
  selectNonArtCompensationLimit,
} from "./countrySettingsSlice";
// ingka styles
import "@ingka/forms/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";

import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import Text from "@ingka/text";

const SSOScopes = process.env.REACT_APP_SSO_SCOPES;

/* 
currently not using additional admin check as
we now have server side validation
There is a first check in order to display the country
settings button
*/


const CountrySettingsComp = () => {
  const { t } = useTranslation();
  const errMessage = t("errorFetching");

  // Hooks
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguageCode);
  const priceMealValue = useSelector(selectPricePerMeal);
  const pricePerKmValue = useSelector(selectPricePerKm);
  const currencyValue = useSelector(selectCurrencySign);
  const artCompensationLimitvalue = useSelector(selectArtCompensationLimit);
  const nonArtCompensationLimitvalue = useSelector(selectNonArtCompensationLimit);
  const [priceMeal, setPricePerMeal] = useState("");
  const [priceKm, setPricePerKm] = useState("");
  const [artCompensationLimit, setArtCompensationPerLimit] = useState("");
  const [nonArtCompensationLimit, setNonArtCompensationPerLimit] = useState("");

  // MSAL
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null); // eslint-disable-line
  const [accessToken, SetAccessToken] = useState(null);

  // UseEffect for MSAL access token only
  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: [SSOScopes],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          SetAccessToken(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                SetAccessToken(accessTokenResponse.accessToken);
              })
              .catch(function (error) {
                // Acquire token interactive failure
                dispatch(
                  setToastMessage({
                    toastRequired: true,
                    toastMessage: errMessage,
                  })
                );
              });
          }
          dispatch(
            setToastMessage({
              toastRequired: true,
              toastMessage: errMessage,
            })
          );
        });
    }
  }, [
    instance,
    accounts,
    inProgress,
    apiData,
    accessToken,
    errMessage,
    dispatch,
  ]);

  const saveCountrySettingsHandler = (evt) => {
    const pricePerMeal = parseFloat(priceMeal);
    const pricePerKm = parseFloat(priceKm);
    const artCompensationPerLimit = parseFloat(artCompensationLimit);
    const nonArtCompensationPerLimit = parseFloat(nonArtCompensationLimit);
    //we have kept non-existig locale.
    if (lang === "en-MM") {
      dispatch(
        setToastMessage({
          toastRequired: true,
        })
      );
      return;
    }
    dispatch(putCountrySettingsAsync({ lang, pricePerMeal, pricePerKm, accessToken, artCompensationPerLimit, nonArtCompensationPerLimit }))

      .then(() => {
        dispatch(
          setToastMessage({
            toastRequired: true,
            toastMessage: "sending update",
          })
        );
        return dispatch(fetchAppDataAsync(lang));
      })
      .catch((error) => {
        dispatch(
          setToastMessage({
            toastRequired: true,
            toastMessage: errMessage,
          })
        );
      });
  };

  const pricePerKmHandler = (evt) => {
    setPricePerKm(evt.target.value);
  };
  const pricePerMealHandler = (evt) => {
    setPricePerMeal(evt.target.value);
  };
  const artCompensationLimitPerHandler = (evt) => {
    setArtCompensationPerLimit(evt.target.value);
  };
  const nonArtCompensationLimitPerHandler = (evt) => {
    setNonArtCompensationPerLimit(evt.target.value);
  };
  return (
    <div className={styles.formEditCountrySettings}>
      <div className={styles.cardECS}>
        <label className={styles.titleECS}>{t("titleECS")}</label>
        {/* Start input field 1 */}
        <div className={styles.userContainer}>
          <div className={styles.userEntryContainer}>
            <label htmlFor="pricePerMeal" className={styles.userLabel}>
              {t("labelInputECS1")}
            </label>

            <InputField
              type="number"
              step="any"
              value={priceMeal}
              min="0"
              onChange={pricePerMealHandler}
            />
          </div>
        </div>
        {/* End input field 1 */}
        {/* Start input field 2 */}
        <div className={styles.userContainer}>
          <div className={styles.userEntryContainer}>
            <label htmlFor="pricePerKm" className={styles.userLabel}>
              {t("labelInputECS2")}
            </label>

            <InputField
              type="number"
              step="any"
              value={priceKm}
              min="0"
              onChange={pricePerKmHandler}
            />
          </div>
        </div>
        <>
          <div className={styles.userContainer}>
            <div className={styles.userEntryContainer}>
              <label htmlFor="artCompensationLimit" className={styles.userLabel}>
                {t("labelInputECS3")}
              </label>

              <InputField
                type="number"
                step="any"
                value={artCompensationLimit}
                min="0"
                onChange={artCompensationLimitPerHandler}
              />
            </div>
          </div>
          <div className={styles.userContainer}>
            <div className={styles.userEntryContainer}>
              <label htmlFor="nonArtCompensationLimit" className={styles.userLabel}>
                {t("labelInputECS4")}
              </label>

              <InputField
                type="number"
                step="any"
                value={nonArtCompensationLimit}
                min="0"
                onChange={nonArtCompensationLimitPerHandler}
              />
            </div>
          </div>
        </>
        <br />
        <Button
          type="primary"
          small={true}
          onClick={saveCountrySettingsHandler}
          text={t("buttonECS")}
        />
      </div>

      <div className={styles.actualSettingsWrapper}>
        <span> {t("labelInputECS1")}</span>

        <Text tagName="h3" headingSize="l">
          {priceMealValue} {currencyValue}
        </Text>
        <br />
        <span> {t("labelInputECS2")}</span>
        <Text tagName="h3" headingSize="l">
          {pricePerKmValue} {currencyValue}
        </Text>
        <>
          <br />
          <span> {t("labelInputECS3")}</span>

          <Text tagName="h3" headingSize="l">
            {artCompensationLimitvalue} {currencyValue}
          </Text>
          <br />
          <span> {t("labelInputECS4")}</span>
          <Text tagName="h3" headingSize="l">
            {nonArtCompensationLimitvalue} {currencyValue}
          </Text>
        </>
      </div>
    </div>
  );
};
const CountrySettings = withTranslation()(CountrySettingsComp);
export { CountrySettings };
