import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
//Ingka styling
import '@ingka/typography/dist/style.css';
import '@ingka/text/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import Text from "@ingka/text";
import Choice, { ChoiceItem } from "@ingka/choice";
import InputField from "@ingka/input-field";
import TextArea from '@ingka/text-area';


import {
  setUnableProductDays,
  setProductType,
  setAdditions,
  setProductPrice,
  selectProductPrice,
  setServicePrice,
  selectServicePrice,
  setNumberOfPersons,
  selectNumberOfPersons,
  setTravelDistance,
  selectTravelDistance,
  setDaysNoCook,
  selectDaysNoCook,
  setExplanation,
  selectExplanation,
} from "./formModuleTwoSlice";

import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";
import styles from "./formModuleTwo.module.css";
// import { selectDeliveryPrice } from "../formModuleOne/formModuleOneSlice";

const FormModuleTwoComp = () => {
  // Selectors
  const productPriceValue = useSelector(selectProductPrice);
  const servicePriceValue = useSelector(selectServicePrice);
  const numberOfPersonsValue = useSelector(selectNumberOfPersons);
  const travelDistanceValue = useSelector(selectTravelDistance);
  const daysNoCookValue = useSelector(selectDaysNoCook);
  const explanationValue = useSelector(selectExplanation);
  const [selectedUnableProductDays, setSelectedUnableProductDays] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  //const [selectedAddition, setSelectedAddition] = useState("");

  // select Addition
  const [additionSelected, setAdditionSelected] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  // Translations
  const { t } = useTranslation();

  // Handlers
  const unableProductDaysHandler = (evt) => {
    if (evt === selectedUnableProductDays) {
      // Reset the choice when the same item is clicked again
      dispatch(setUnableProductDays({ unableToUseProductDays: "" }));
      setSelectedUnableProductDays(null);
    } else {
      // Set the selectedUnableProductDays state to the clicked item
      dispatch(setUnableProductDays({ unableToUseProductDays: evt }));
      setSelectedUnableProductDays(evt);
    }
  };
  const productTypeHandler = (evt) => {
    if (evt === selectedProductType) {
      // Reset the choice when the same item is clicked again
      dispatch(setProductType({ productType: "" }));
      setSelectedProductType(null);
    } else {
      // Set the selectedProductType state to the clicked item
      dispatch(setProductType({ productType: evt }));
      setSelectedProductType(evt);
    }
  };
  const additionsHandler = (evt) => {
    if (evt === additionSelected) {
      // Reset the choice when the same item is clicked again
      dispatch(setAdditions({ addition: "" }));
      setAdditionSelected("");
    } else {
      // Set the additionSelected state to the clicked item
      dispatch(setAdditions({ addition: evt }));
      setAdditionSelected(evt);
      if (additionSelected === "mod2Title3.Option2") {
        dispatch(setTravelDistance({ travelDistance: "" }));
      } else if (additionSelected === "mod2Title3.Option3") {
        dispatch(setServicePrice({ servicePrice: "" }));
      } else if (additionSelected === "mod2Title3.Option4") {
        dispatch(setNumberOfPersons({ numberOfPersons: "" }));
        dispatch(setDaysNoCook({ daysNoCook: "" }));
      }
    }
  };
  const productPriceHandler = (evt) => {
    dispatch(setProductPrice({ productPrice: evt.target.value }));
  };
  const servicePriceHandler = (evt) => {
    dispatch(setServicePrice({ servicePrice: evt.target.value }));
  };
  const personsInHouseholdHandler = (evt) => {
    dispatch(setNumberOfPersons({ numberOfPersons: evt.target.value }));
  };

  const travelDistanceHandler = (evt) => {
    dispatch(setTravelDistance({ travelDistance: evt.target.value }));
  };
  const daysNoCookHandler = (evt) => {
    dispatch(setDaysNoCook({ daysNoCook: evt.target.value }));
  };
  const explanationHandler = (evt) => {
    dispatch(setExplanation({ explanation: evt.target.value }));
  };

  const gaEventTracker = sendAnalyticsEvent('product_information');

  return (
    <div>
      <div className={styles.moduleContainer}>
        {/*Options section*/}
        <div className={styles.sectionContainer}>
          {/** Title */}
          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("mod2Header.Text")}
              </Text>
            </div>

            {/*** 1st title *****   How long will the customer be unable to use the product/s   ****/}
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title1.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title1.Option1")}
                    id="mod2Title1.Option1"
                    isSelected={selectedUnableProductDays === "mod2Title1.Option1"}
                    onClick={(evt) => { gaEventTracker('LessThanWeek'); return unableProductDaysHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option2")}
                    id="mod2Title1.Option2"
                    isSelected={selectedUnableProductDays === "mod2Title1.Option2"}
                    onClick={(evt) => { gaEventTracker('TwoWeeks'); return unableProductDaysHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option3")}
                    id="mod2Title1.Option3"
                    isSelected={selectedUnableProductDays === "mod2Title1.Option3"}
                    onClick={(evt) => { gaEventTracker('FourWeeks'); return unableProductDaysHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title1.Option4")}
                    id="mod2Title1.Option4"
                    isSelected={selectedUnableProductDays === "mod2Title1.Option4"}
                    onClick={(evt) => { gaEventTracker('MoreThan7Weeks'); return unableProductDaysHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>

          {/*2nd title ******  Product type  ***********  Product type ****/}
          <div className={styles.module}>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title2.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title2.Option1")}
                    id="mod2Title2.Option1"
                    isSelected={selectedProductType === "mod2Title2.Option1"}
                    onClick={(evt) => { gaEventTracker('CriticalSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title2.Option2")}
                    id="mod2Title2.Option2"
                    isSelected={selectedProductType === "mod2Title2.Option2"}
                    onClick={(evt) => { gaEventTracker('AnnoyingSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod2Title2.Option3")}
                    id="mod2Title2.Option3"
                    isSelected={selectedProductType === "mod2Title2.Option3"}
                    onClick={(evt) => { gaEventTracker('MinimalSeverity'); return productTypeHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
            <div className={styles.userEntryContainer}>
              <label htmlFor="mod2_productPrice" className={styles.userLabel}>
                {t("mod2Input.Label1")}
              </label>
              <InputField
                type="number"
                step="any"
                name="mod2_productPrice"
                value={productPriceValue || 0}
                min="0"
                onChange={(evt) => { gaEventTracker('ProductPriceInput'); return productPriceHandler(evt, dispatch) }}
              />
            </div>
          </div>

          {/* 3rd title   *****   ADDITIONS     **********   ADDITIONS     *****/}
          <div className={styles.module}>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title3.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title3.Option2")}
                    id="mod2Title3.Option2"
                    isSelected={additionSelected === "mod2Title3.Option2"}
                    onClick={(evt) => { gaEventTracker('Drive'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  {(additionSelected === "mod2Title3.Option2" ||
                    travelDistanceValue > 0) && (
                      <div className={styles.userEntryContainer}>
                        <label
                          htmlFor="mod2_travel_distance"
                          className={styles.userLabel}
                        >
                          {t("mod2Input.Label4")}
                        </label>
                        <InputField
                          type="number"
                          step="any"
                          name="mod2_travel_distance"
                          value={travelDistanceValue || 0}
                          min="0"
                          onChange={(evt) => { gaEventTracker('DriveDistanceTravelledInput'); return travelDistanceHandler(evt, dispatch) }}
                        />
                      </div>
                    )}
                  <ChoiceItem
                    title={t("mod2Title3.Option3")}
                    id="mod2Title3.Option3"
                    isSelected={additionSelected === "mod2Title3.Option3"}
                    onClick={(evt) => { gaEventTracker('AssembleDismantle'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  {(additionSelected === "mod2Title3.Option3" ||
                    servicePriceValue > 0) && (
                      <div className={styles.userEntryContainer}>
                        <label
                          htmlFor="mod2_servicePrice"
                          className={styles.userLabel}
                        >
                          {t("mod2Input.Label2")}
                        </label>
                        <InputField
                          type="number"
                          step="any"
                          name="mod2_servicePrice"
                          value={servicePriceValue || 0}
                          min="0"
                          onChange={(evt) => { gaEventTracker('AssembleDismantleServiceInput'); return servicePriceHandler(evt, dispatch) }}
                        />
                      </div>
                    )}
                  <ChoiceItem
                    title={t("mod2Title3.Option4")}
                    id="mod2Title3.Option4"
                    isSelected={additionSelected === "mod2Title3.Option4"}
                    onClick={(evt) => { gaEventTracker('NoCookingEatOut'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>
          {(additionSelected === "mod2Title3.Option4" ||
            numberOfPersonsValue > 0) && (
              <div className={styles.userContainer}>
                <div className={styles.userEntryContainer}>
                  <label
                    htmlFor="mod2_personsHousehold"
                    className={styles.userLabel}
                  >
                    {t("mod2Input.Label3")}
                  </label>
                  <InputField
                    type="number"
                    step="any"
                    name="mod2_personsHousehold"
                    value={numberOfPersonsValue || 0}
                    min="0"
                    onChange={(evt) => { gaEventTracker('PeopleInHouseholdInput'); return personsInHouseholdHandler(evt, dispatch) }}
                  />
                </div>
                <div className={styles.userEntryContainer}>
                  <label htmlFor="mod2_daysNoCook" className={styles.userLabel}>
                    {t("mod2Input.Label5")}
                  </label>
                  <InputField
                    type="number"
                    step="any"
                    name="mod2_daysNoCook"
                    value={daysNoCookValue || 0}
                    min="0"
                    onChange={(evt) => { gaEventTracker('UnableToCookDaysInput'); return daysNoCookHandler(evt, dispatch) }}
                  />
                </div>
              </div>
            )}

          {/****** Text Area ******   *****/}
          <div className={styles.userEntryContainer}>
            <TextArea
              label={t("mod2Textarea.Label")}
              id="freeTextExplanation"
              name="userTextArea"
              value={explanationValue}
              onChange={(evt) => {
                gaEventTracker('AddlCompensationInput');
                return explanationHandler(evt, dispatch);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FormModuleTwo = withTranslation()(FormModuleTwoComp);
export { FormModuleTwo };
