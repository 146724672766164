import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
//Ingka styling
import '@ingka/typography/dist/style.css';
import '@ingka/text/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import Text from "@ingka/text";
import Choice, { ChoiceItem } from "@ingka/choice";
import InputField from "@ingka/input-field";
import TextArea from '@ingka/text-area';


import {
  setAdditions,
  setServicePrice,
  selectServicePrice,
  setNumberOfPersons,
  selectNumberOfPersons,
  setTravelDistance,
  selectTravelDistance,
  setDaysNoCook,
  selectDaysNoCook,
  setExplanation,
  selectExplanation,
} from "../FormModuleTwo/formModuleTwoSlice";

import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";
import styles from "../CategoryModuleTwo/categoryModuleTwo.module.css";
// import { selectDeliveryPrice } from "../formModuleOne/formModuleOneSlice";

const CategoryModuleTwoComp = () => {
  // Selectors
  const servicePriceValue = useSelector(selectServicePrice);
  const numberOfPersonsValue = useSelector(selectNumberOfPersons);
  const travelDistanceValue = useSelector(selectTravelDistance);
  const daysNoCookValue = useSelector(selectDaysNoCook);
  const explanationValue = useSelector(selectExplanation);
  //const [selectedAddition, setSelectedAddition] = useState("");

  // select Addition
  const [additionSelected, setAdditionSelected] = useState("");

  // Dispatch
  const dispatch = useDispatch();

  // Translations
  const { t } = useTranslation();

  // Handlers
  const additionsHandler = (evt) => {
    if (evt === additionSelected) {
      // Reset the choice when the same item is clicked again
      dispatch(setAdditions({ addition: "" }));
      setAdditionSelected("");
    } else {
      // Set the additionSelected state to the clicked item
      dispatch(setAdditions({ addition: evt }));
      setAdditionSelected(evt);
      if (additionSelected === "mod2Title3.Option2") {
        dispatch(setTravelDistance({ travelDistance: "" }));
      } else if (additionSelected === "mod2Title3.Option3") {
        dispatch(setServicePrice({ servicePrice: "" }));
      } else if (additionSelected === "mod2Title3.Option4") {
        dispatch(setNumberOfPersons({ numberOfPersons: "" }));
        dispatch(setDaysNoCook({ daysNoCook: "" }));
      }
    }
  };
  const servicePriceHandler = (evt) => {
    dispatch(setServicePrice({ servicePrice: evt.target.value }));
  };
  const personsInHouseholdHandler = (evt) => {
    dispatch(setNumberOfPersons({ numberOfPersons: evt.target.value }));
  };

  const travelDistanceHandler = (evt) => {
    dispatch(setTravelDistance({ travelDistance: evt.target.value }));
  };
  const daysNoCookHandler = (evt) => {
    dispatch(setDaysNoCook({ daysNoCook: evt.target.value }));
  };
  const explanationHandler = (evt) => {
    dispatch(setExplanation({ explanation: evt.target.value }));
  };

  const gaEventTracker = sendAnalyticsEvent('product_information');

  return (
    <div>
      <div className={styles.moduleContainer}>
        {/*Options section*/}
        <div className={styles.sectionContainer}>
          {/* 2nd Category  Questions     *****/}
          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("category2.Header")}
              </Text>
            </div>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Title3.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod2Title3.Option2")}
                    id="mod2Title3.Option2"
                    onClick={(evt) => { gaEventTracker('Drive'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <div className={styles.userEntryContainer}>
                    <label
                      htmlFor="mod2_travel_distance"
                      className={styles.userLabel}
                    >
                      {t("mod2Input.Label4")}
                    </label>
                    <InputField
                      type="number"
                      step="any"
                      name="mod2_travel_distance"
                      value={travelDistanceValue || 0}
                      min="0"
                      onChange={(evt) => { gaEventTracker('DriveDistanceTravelledInput'); return travelDistanceHandler(evt, dispatch) }}
                    />
                  </div>
                  <ChoiceItem
                    title={t("mod2Title3.Option3")}
                    id="mod2Title3.Option3"
                    onClick={(evt) => { gaEventTracker('AssembleDismantle'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <div className={styles.userEntryContainer}>
                    <label
                      htmlFor="mod2_servicePrice"
                      className={styles.userLabel}
                    >
                      {t("mod2Input.Label2")}
                    </label>
                    <InputField
                      type="number"
                      step="any"
                      name="mod2_servicePrice"
                      value={servicePriceValue || 0}
                      min="0"
                      onChange={(evt) => { gaEventTracker('AssembleDismantleServiceInput'); return servicePriceHandler(evt, dispatch) }}
                    />
                  </div>
                  <ChoiceItem
                    title={t("mod2Title3.Option4")}
                    id="mod2Title3.Option4"
                    onClick={(evt) => { gaEventTracker('NoCookingEatOut'); return additionsHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
            <div className={styles.userContainer}>
              <div className={styles.userEntryContainer}>
                <label
                  htmlFor="mod2_personsHousehold"
                  className={styles.userLabel}
                >
                  {t("mod2Input.Label3")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  name="mod2_personsHousehold"
                  value={numberOfPersonsValue || 0}
                  min="0"
                  onChange={(evt) => { gaEventTracker('PeopleInHouseholdInput'); return personsInHouseholdHandler(evt, dispatch) }}
                />
              </div>
              <div className={styles.userEntryContainer}>
                <label htmlFor="mod2_daysNoCook" className={styles.userLabel}>
                  {t("mod2Input.Label5")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  name="mod2_daysNoCook"
                  value={daysNoCookValue || 0}
                  min="0"
                  onChange={(evt) => { gaEventTracker('UnableToCookDaysInput'); return daysNoCookHandler(evt, dispatch) }}
                />
              </div>
            </div>
          </div>

          {/****** Text Area ******   *****/}
          <div className={styles.userEntryContainer}>
            <TextArea
              label={t("mod2Textarea.Label")}
              id="freeTextExplanation"
              name="userTextArea"
              value={explanationValue}
              onChange={(evt) => {
                gaEventTracker('AddlCompensationInput');
                return explanationHandler(evt, dispatch);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoryModuleTwo = withTranslation()(CategoryModuleTwoComp);
export { CategoryModuleTwo };
