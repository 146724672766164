import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAppData } from "./calculatorAPI";

const initialState = {
  langCode: null,
  pricePerMeal: 0,
  pricePerKm: 0,
  currencySign: null,
  status: "idle",
  errorMessage:null,
  artCompensationLimit: 0,
  nonArtCompensationLimit: 0
};

export const fetchAppDataAsync = createAsyncThunk(
  "calc/fetchAppData",
  async (amount) => {
    const response = await fetchAppData(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const calculatorSlice = createSlice({
  name: "calc",
  initialState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAppDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.pricePerMeal = action.payload.price_per_meal;
        state.pricePerKm = action.payload.price_per_km;
        state.langCode = action.payload.lang;
        state.currencySign = action.payload.currency;
        state.artCompensationLimit = action.payload.art_compensation_limit;
        state.nonArtCompensationLimit = action.payload.non_art_compensation_limit;
      })
      .addCase(fetchAppDataAsync.rejected, (state, {error})=>{
        state.status='rejected';
        state.errorMessage = error.message
      } )
  },
});

export const {
  setServiceFailedSelect,
  setUnableProductDays,
  setProductType,
  setAdditions,
  setIssueType,
  setPeriodPurchased,
} = calculatorSlice.actions;

export const selectPricePerKm = (state) => state.appData.pricePerKm;
export const selectCurrencySign = (state) => state.appData.currencySign;
export const selectPricePerMeal = (state) => state.appData.pricePerMeal;
export const selectStatus = (state) => state.appData.status;
export const selectArtCompensationLimit = (state) => state.appData.artCompensationLimit;
export const selectNonArtCompensationLimit = (state) => state.appData.nonArtCompensationLimit;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

//export const selectServiceFailed = (state) => state.calculator.failedServiceOptionSelected;
//export const selectUnableProductDays = (state) => state.calculator.unableToUseOptionSelected;
//export const selectProductType = (state) => state.calculator.productTypeOptionSelected;
//export const selectAdditions = (state) => state.calculator.additionsSelected;
//export const selectIssueType = (state) => state.calculator.issueTypeSelected;
// export const selectPeriodPurchased = (state) => state.calculator.periodPurchasedSelected;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectServiceFailed(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default calculatorSlice.reducer;
 */
