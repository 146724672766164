import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toastRequired: false,
    toastMessage: ""
};

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setToastRequired: (state, action) => {
            state.toastRequired = action.payload.toastRequired;
        },
        setToastMessage: (state, action) => {
            state.toastMessage = action.payload.toastMessage;
            state.toastRequired = action.payload.toastRequired;
        },
    },
    extraReducers: (builder) => { },
});


export const { setToastRequired, setToastMessage } =
    toastSlice.actions;

export const selectToastRequired = (state) =>
    state.toast.toastRequired;
export const selectToastMessage = (state) => state.toast.toastMessage;
