import React from 'react';
import { withTranslation } from 'react-i18next';



import styles from './Calculator.module.css';

// Modules

import { FormModuleOne } from "../FormModuleOne/FormModuleOne";
import { FormModuleTwo } from "../FormModuleTwo/FormModuleTwo";
import { FormModuleThree } from "../FormModuleThree/FormModuleThree";

const CalculatorComp = () => {

  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.containerForm}>

          <FormModuleOne />

          <div className={styles.separator}></div>

          <FormModuleTwo />

          <div className={styles.separator}></div>

          <FormModuleThree />

          <div className={styles.separator}></div>

        </div>
      </div>
    </div>
  );
}
const Calculator = withTranslation()(CalculatorComp)
export { Calculator }