import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import '@ingka/typography/dist/style.css';
import '@ingka/text/dist/style.css';
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import Text from "@ingka/text";
import InputField from "@ingka/input-field";
import Choice, { ChoiceItem } from "@ingka/choice";
import styles from "./categoryModuleThree.module.css";

import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";
import { setIssueType, setPeriodPurchased } from "../FormModuleThree/formModuleThreeSlice";
import {
  setAdditions,
  setTravelDistance,
  selectTravelDistance,
  setProductPrice,
  selectProductPrice,
} from "../FormModuleTwo/formModuleTwoSlice";

const CategoryModuleThreeComp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gaEventTracker = sendAnalyticsEvent('visual_defects_issues');
  const [selectedIssueType, setSelectedIssueType] = useState(null);
  const [selectedPeriodPurchased, setSelectedPeriodPurchased] = useState(null);
  const [additionSelected, setAdditionSelected] = useState("");
  const productPriceValue = useSelector(selectProductPrice);

  const issueTypeHandler = (evt) => {
    if (evt === selectedIssueType) {
      // Reset the choice and input field value when the same item is clicked again
      dispatch(setIssueType({ issueType: "" }));
      setSelectedIssueType(null);
    } else {
      // Set the selectedIssueType state to the clicked item
      dispatch(setIssueType({ issueType: evt }));
      setSelectedIssueType(evt);
    }
  };
  const periodPurchasedHandler = (evt) => {
    if (evt === selectedPeriodPurchased) {
      // Reset the choice and input field value when the same item is clicked again
      dispatch(setPeriodPurchased({ periodPurchased: "" }));
      setSelectedPeriodPurchased(null);
    } else {
      // Set the selectedPeriodPurchased state to the clicked item
      dispatch(setPeriodPurchased({ periodPurchased: evt }));
      setSelectedPeriodPurchased(evt);
    }
  };

  const travelDistanceHandler = (evt) => {
    dispatch(setTravelDistance({ travelDistance: evt.target.value }));
  };

  const additionsHandler = (evt) => {
    if (evt === additionSelected) {
      // Reset the choice when the same item is clicked again
      dispatch(setAdditions({ addition: "" }));
      setAdditionSelected("");
    } else {
      // Set the additionSelected state to the clicked item
      dispatch(setAdditions({ addition: evt }));
      setAdditionSelected(evt);
      if (additionSelected === "mod2Title3.Option2") {
        dispatch(setTravelDistance({ travelDistance: "" }));
      }
    }
  };

  const travelDistanceValue = useSelector(selectTravelDistance);

  const productPriceHandler = (evt) => {
    dispatch(setProductPrice({ productPrice: evt.target.value }));
  };

  return (
    <>
      <div className={styles.moduleContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("category3.Header")}
              </Text>
            </div>
            <div className={styles.subtitle1}>
              <Text tagName="h4" headingSize="s">
                {t("mod2Input.Label1")}
              </Text>
            </div>
            <div className={styles.moduleProductPriceContainer}>
              <div className={styles.userContainer}>
                <div className={styles.userEntryProductPriceContainer}>
                  <div className={styles.userEntryContainer}>
                    <label htmlFor="mod2_productPrice" className={styles.userLabel}>
                      {t("mod2Input.Label1")}
                    </label>
                    <InputField
                      type="number"
                      step="any"
                      name="mod2_productPrice"
                      value={productPriceValue || 0}
                      min="0"
                      onChange={(evt) => { gaEventTracker('ProductPriceInput'); return productPriceHandler(evt, dispatch) }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.subtitle1}>
              <Text tagName="h4" headingSize="s">
                {t("mod3Title1.Text")}
              </Text>
            </div>
            <div className={styles.inputsContainer}>
              {/*1st option*/}
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod3Title1.Option1")}
                    id="mod3Title1.Option1"
                    // isSelected={selectedIssueType === "mod3Title1.Option1"}
                    onClick={(evt) => { gaEventTracker('NonFunctional'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 1st option*/}
                  {/*2nd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title1.Option2")}
                    id="mod3Title1.Option2"
                    // isSelected={selectedIssueType === "mod3Title1.Option2"}
                    onClick={(evt) => { gaEventTracker('AestheticHeavy'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 2nd option*/}
                  {/*3rd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title1.Option3")}
                    id="mod3Title1.Option3"
                    // isSelected={selectedIssueType === "mod3Title1.Option3"}
                    onClick={(evt) => { gaEventTracker('AestheticLight'); return issueTypeHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* End 3rd option*/}
                </Choice>
              </div>
            </div>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod3Title2.Text")}
              </Text>
              {/* <h5>NF | AH | AL</h5> */}
            </div>
            <div className={styles.inputsContainer}>
              {/*1st option*/}
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod3Title2.Option1")}
                    id="mod3Title2.Option1"
                    // isSelected={selectedPeriodPurchased === "mod3Title2.Option1"}
                    onClick={(evt) => { gaEventTracker('OneMonth'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 1st option*/}
                  {/*2nd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option2")}
                    id="mod3Title2.Option2"
                    // isSelected={selectedPeriodPurchased === "mod3Title2.Option2"}
                    onClick={(evt) => { gaEventTracker('OneYear'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 2nd option*/}
                  {/*3rd option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option3")}
                    id="mod3Title2.Option3"
                    // isSelected={selectedPeriodPurchased === "mod3Title2.Option3"}
                    onClick={(evt) => { gaEventTracker('LessThan2Years'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* </div> */}
                  {/* End 3rd option*/}
                  {/*4th option*/}
                  {/* <div className={styles.optionContainer}> */}
                  <ChoiceItem
                    title={t("mod3Title2.Option4")}
                    id="mod3Title2.Option4"
                    // isSelected={selectedPeriodPurchased === "mod3Title2.Option4"}
                    onClick={(evt) => { gaEventTracker('MoreThan2Years'); return periodPurchasedHandler(evt, dispatch) }}
                  >
                  </ChoiceItem>
                  {/* End 4th option*/}
                </Choice>
              </div>
            </div>
            <div className={styles.module}>
              <div className={styles.subtitle}>
                <Text tagName="h4" headingSize="s">
                  {t("mod2Title3.Text")}
                </Text>
              </div>
              <div className={styles.inputsContainer}>
                <div className={styles.optionContainer}>
                  <Choice deselectable>
                    <ChoiceItem
                      title={t("mod2Title3.Option2")}
                      id="mod2Title3.Option2"
                      // isSelected={additionSelected === "mod2Title3.Option2"}
                      onClick={(evt) => { gaEventTracker('Drive'); return additionsHandler(evt, dispatch) }}
                    ></ChoiceItem>
                    {(additionSelected === "mod2Title3.Option2" ||
                      travelDistanceValue > 0) && (
                        <div className={styles.userEntryContainer}>
                          <label
                            htmlFor="mod2_travel_distance"
                            className={styles.userLabel}
                          >
                            {t("mod2Input.Label4")}
                          </label>
                          <InputField
                            type="number"
                            step="any"
                            name="mod2_travel_distance"
                            value={travelDistanceValue || 0}
                            min="0"
                            onChange={(evt) => { gaEventTracker('DriveDistanceTravelledInput'); return travelDistanceHandler(evt, dispatch) }}
                          />
                        </div>
                      )}
                  </Choice>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CategoryModuleThree = withTranslation()(CategoryModuleThreeComp);
export { CategoryModuleThree };